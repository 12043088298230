import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Image from 'react-bootstrap/Image'
import {FaPlusCircle} from 'react-icons/fa'
import '../styles/_info.scss'


export default function InfoScreen(props) {
  const [questionsOpen, setQuestionsOpen] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
  });

  return (
    <div id="info-page">

      <img src="static/info-header-april25.png" className="header-img" />

      <Container className="info-content">
        <b className="rain-or-shine">RAIN OR SHINE</b>
        <h2 className='section-header'>About Viva CalleSJ</h2>
        <p className="about-text">
          Viva CalleSJ is an "open streets" event, based on the global concept 
          where roads are opened to people and closed to cars. Streets become 
          “paved parks” where residents can run, walk, bike, skate, and play. 
          All ages and abilities can come out and take part in activities, services, 
          cultural events, and explore San José. Visit&nbsp;
          <a href="http://www.sanjoseca.gov/placemaking" target="_blank">our Placemaking page</a> 
          &nbsp;to learn more about our other programs!
        </p>

        <Image src="static/event-pics/april25/1.png" className='event-pic' fluid /><br/><br/>
        <Image src="static/event-pics/april25/2.png" className='event-pic' fluid /><br/><br/>
        <Image src="static/event-pics/april25/3.png" className='event-pic' fluid /><br/><br/>
        <Image src="static/event-pics/april25/4.png" className='event-pic' fluid /><br/><br/>
        <Image src="static/event-pics/april25/5.png" className='event-pic' fluid /><br/><br/>

        <h2 className='section-header'>Support Viva CalleSJ</h2>
        <div className="support-section">
          If you'd like to support Viva CalleSJ, there are 2 options:
          <ul>
            <li><a href="sms:24365&body=VCSJ">Text VCSJ to 24365</a></li>
            <li>Visit <a href="https://donatenow.networkforgood.org/DirectProgramFunding" target="_blank">our donation page</a> and select <i>"Viva CalleSJ Community Fund</i>"</li>
          </ul>
        </div>

        <h2 className='section-header'>Featured Sponsors</h2>
        <Row className='sponser-logos-row'>
          <Col xs={6} md={3} className="img-col">
            <Image src="static/logos/stanford-medicine.png" fluid />
          </Col>
          <Col xs={6} md={3} className="img-col">
            <Image src="static/logos/vta.png" fluid />
          </Col>
          <Col xs={6} md={3} className="img-col">
            <Image src="static/logos/wild949.png" fluid />
          </Col>
          <Col xs={6} md={3} className="img-col">
            <Image src="static/logos/groundworks.png" fluid />
          </Col>
          <Col xs={6} md={3} className="img-col">
            <Image src="static/logos/osa.png" fluid />
          </Col>
          <Col xs={6} md={3} className="img-col">
            <Image src="static/logos/mti.png" fluid />
          </Col>
          <Col xs={6} md={3} className="img-col">
            <Image src="static/logos/baunfire.png" fluid />
          </Col>
        </Row>

        <h2 className='section-header'>FAQs</h2>
        <div className="faqs">
          {/* Q1 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 1: !questionsOpen[1]})}
              aria-controls="faq1"
              aria-expanded={questionsOpen[1]}
            >
            What Is Viva CalleSJ?
            </Button>
          </div>
          <Collapse in={questionsOpen[1]}>
            <div className="faq-answer" id="faq1">
              Viva CalleSJ is a free program that temporarily closes miles of San Jose streets to bring communities
              together to walk, bike, skate, play, and explore the city like never before.
            </div>
          </Collapse>

          {/* Q2 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 2: !questionsOpen[2]})}
              aria-controls="faq2"
              aria-expanded={questionsOpen[2]}
            >
            When will the Viva CalleSJ route go into effect?
            </Button>
          </div>
          <Collapse in={questionsOpen[2]}>
            <div className="faq-answer" id="faq2">
              Viva CalleSJ events open up San José streets to people during Sunday events. There are some important
              times to remember if you live or work along the route. Times are subject to change; please visit the
              Events page to learn about specific routes. Generally, these are the times to remember:<br/><br/>
              1:00 am: Cars parked on the street along the route will be towed.<br/>
              6:00 am: Streets are closed to traffic. No entry or exit on the route. <br/>
              10:00 am: Viva CalleSJ event officially begins. <br/>
              3:00 pm: Viva CalleSJ event officially ends and the route re-opens to cars and parking.  <br/>
            </div>
          </Collapse>

          {/* Q3 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 3: !questionsOpen[3]})}
              aria-controls="faq3"
              aria-expanded={questionsOpen[3]}
            >
            When will the Viva CalleSJ route re-open?
            </Button>
          </div>
          <Collapse in={questionsOpen[3]}>
            <div className="faq-answer" id="faq3">
              At 3:00 pm, after the Viva CalleSJ event, the roads begin re-opening to cars.
            </div>
          </Collapse>

          {/* Q4 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 4: !questionsOpen[4]})}
              aria-controls="faq4"
              aria-expanded={questionsOpen[4]}
            >
            Are there bathrooms along the route?
            </Button>
          </div>
          <Collapse in={questionsOpen[4]}>
            <div className="faq-answer" id="faq4">
              Yes. There will be portable toilets, including ADA accessible ones, along the Viva CalleSJ
              route and at Activity Hubs.
            </div>
          </Collapse>

          {/* Q5 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 5: !questionsOpen[5]})}
              aria-controls="faq5"
              aria-expanded={questionsOpen[5]}
            >
            Where can I find water along the route?
            </Button>
          </div>
          <Collapse in={questionsOpen[5]}>
            <div className="faq-answer" id="faq5">
              Free water will be available at hydration stations at Activity Hubs along the route. We recommend
              bringing your own water bottle to fill up.
            </div>
          </Collapse>

          {/* Q6 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 6: !questionsOpen[6]})}
              aria-controls="faq6"
              aria-expanded={questionsOpen[6]}
            >
            Is the Viva CalleSJ route always the same?
            </Button>
          </div>
          <Collapse in={questionsOpen[6]}>
            <div className="faq-answer" id="faq6">
              No. The Viva CalleSJ route will change with each event in order to engage all of San José’s
              communities and explore all of the city’s gems.
            </div>
          </Collapse>

          {/* Q7 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 7: !questionsOpen[7]})}
              aria-controls="faq7"
              aria-expanded={questionsOpen[7]}
            >
            Is this a race?
            </Button>
          </div>
          <Collapse in={questionsOpen[7]}>
            <div className="faq-answer" id="faq7">
              No. There is no “start” or “finish” on a Viva CalleSJ route. The streets are wide open to allow
              participants to enjoy the space as they see fit. Participants should plan for a leisurely
              day of riding, skating, jogging, or playing. Be patient with those around you and the speeds at
              which they are traveling. Never pass on the right, only on the left.
            </div>
          </Collapse>

          {/* Q8 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 8: !questionsOpen[8]})}
              aria-controls="faq8"
              aria-expanded={questionsOpen[8]}
            >
            Can I ride my skateboard on the route?
            </Button>
          </div>
          <Collapse in={questionsOpen[8]}>
            <div className="faq-answer" id="faq8">
              Yes. Skateboards, roller skates, unicycles, pogo sticks, all kinds of bicycles, and any other
              means of transportation without a motor are allowed. Officially marked means of transport for
              those with disabilities are also allowed. Ride with care – you should never ride at high speeds or
              engage in behavior that makes others feel uncomfortable or unsafe.
            </div>
          </Collapse>

          {/* Q9 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 9: !questionsOpen[9]})}
              aria-controls="faq9"
              aria-expanded={questionsOpen[9]}
            >
            I don't have a bike. Can I still enjoy Viva CalleSJ?
            </Button>
          </div>
          <Collapse in={questionsOpen[9]}>
            <div className="faq-answer" id="faq9">
              Absolutely! At Viva CalleSJ people can run, walk dogs, rollerblade, gather to picnic, enjoy
              live entertainment and pop-up activities, shop at local stores, and dine at local restaurants!
            </div>
          </Collapse>

          {/* Q10 */}
          <div className="question-row">
            <FaPlusCircle/>
            <Button
              variant="link"
              className="faq-header-btn"
              onClick={() => setQuestionsOpen({...questionsOpen, 10: !questionsOpen[10]})}
              aria-controls="faq10"
              aria-expanded={questionsOpen[10]}
            >
            Will there be places for me to lock up my bike?
            </Button>
          </div>
          <Collapse in={questionsOpen[10]}>
            <div className="faq-answer" id="faq10">
              Yes. Monitored bike parking will be available at Activity Hubs. However, it is highly recommended
              that you bring your own lock.
            </div>
          </Collapse>


        </div>
      </Container>
      <div className="back-to-map" onClick={() => props.backToMap()}>
        <p>Back to Map</p>
      </div>
    </div>
  )
}